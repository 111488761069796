<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" :md="fromSdkPage ? '11' : '9'">
        <v-row>
          <v-col :class="fromSdkPage ? '' : 'mt-15'">
            <v-card
              :outlined="$vuetify.theme.dark"
              class="cardBackgroud pa-12"
              color=""
              flat
            >
              <v-row>
                <v-col class="d-flex align-center" cols="6">
                  <div style="margin-left: 68px; padding: 40px 0">
                    <v-row>
                      <v-col cols="12">
                        <div>
                          <v-icon class="mt-n2" color="primary" left size="40"
                            >mdi-timeline-text-outline
                          </v-icon>
                          <span>
                            <span class="headingTitle mr-2 semi-bold"
                              >Sessions</span
                            >
                            <sup class="smallFont">BETA</sup>
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <div class="mb-3" style="max-width: 43ch">
                          <b>Track everything</b> and collect
                          <b>feedback</b> from your testers from inside your app
                          to make it ready for real users in app stores.
                        </div>
                        <div class="mb-3">
                          2-mins to integrate inside your app!
                        </div>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        appInfo?.settings?.sessions_enabled &&
                        $route.params.app_id
                      "
                    >
                      <v-col cols="12">
                        <v-btn
                          color="primary"
                          class="text-transform-none mr-2"
                          :outlined="$vuetify.theme.dark"
                          @click="selectToken"
                        >
                          <v-icon left>mdi-select-all</v-icon>
                          Select App Token
                        </v-btn>
                        <v-btn
                          color="primary"
                          class="text-transform-none mr-2"
                          :outlined="$vuetify.theme.dark"
                          link
                          text
                          target="_blank"
                          href="https://testapp.io/sessions"
                        >
                          <v-icon left>mdi-page-next-outline</v-icon>
                          Learn more
                        </v-btn>
                      </v-col>
                      <v-col v-if="selectedToken" cols="12">
                        <v-chip
                          v-if="selectedToken"
                          class="black--text cursor-pointer"
                          color="grey"
                          label
                          @click="$emit('select-token')"
                        >
                          <MaxText :text="selectedToken" max="40"></MaxText>
                        </v-chip>
                        <v-btn
                          v-if="selectedToken"
                          :disabled="!selectedToken"
                          icon
                          @click="copy(selectedToken)"
                        >
                          <v-icon v-text="'mdi-content-copy'"></v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <!-- Lock Package -->
                    <v-row
                      class="d-flex align-center"
                      v-if="
                        !appInfo?.settings?.lock_package && $route.params.app_id
                      "
                    >
                      <v-col cols="auto">
                        <v-checkbox
                          :input-value="tempLockPackage"
                          label="Lock Package"
                          @change="enableLockPackage"
                          :disabled="loadingPackage"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="auto">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small class="ml-2">
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          <span>
                            Locking the package ensures that releases and
                            sessions are tied together, providing better
                            tracking and analysis.
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="auto">
                        <v-progress-circular
                          v-if="loadingPackage"
                          indeterminate
                          color="primary"
                          size="24"
                        ></v-progress-circular>
                      </v-col>
                    </v-row>
                    <v-row v-if="!$route.params.app_id">
                      <v-col cols="12">
                        <v-btn
                          :outlined="$vuetify.theme.dark"
                          color="primary"
                          class="text-transform-none"
                          @click="
                            $router.push({
                              name: 'apps',
                              query: {
                                to: 'app-integrations',
                                tab: 'sessions',
                              },
                            })
                          "
                          >Get started</v-btn
                        >
                      </v-col>
                    </v-row>

                    <!-- Lock Unique Releases -->
                    <v-row
                      class="d-flex align-center"
                      v-if="
                        !appInfo?.settings?.lock_unique_releases &&
                        $route.params.app_id
                      "
                    >
                      <v-col cols="auto">
                        <v-checkbox
                          :input-value="tempLockUniqueReleases"
                          label="Unique Releases"
                          @change="enableLockUniqueReleases"
                          :disabled="loadingReleaseIncrement"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="auto">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small class="ml-2">
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          <span>
                            Locking unique releases allows for precise error
                            tracking by mapping errors directly to specific
                            release versions.
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="auto">
                        <v-progress-circular
                          v-if="loadingReleaseIncrement"
                          indeterminate
                          color="primary"
                          size="24"
                        ></v-progress-circular>
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="
                        !appInfo?.settings?.sessions_enabled &&
                        $route.params.app_id
                      "
                      class="pl-3 mt-4"
                    >
                      <v-btn
                        :disabled="!isEnableButtonActive || busy"
                        color="primary"
                        @click="enableSessions"
                        >Enable</v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
                <v-col class="text-right" cols="6" style="display: flex">
                  <img
                    src="/media/sessions/sessions.svg"
                    style="aspect-ratio: 606 / 446"
                    width="100%"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" style="position: sticky; top: 52px">
            <v-tabs v-model="tab">
              <tab
                v-for="(value, key) in tabOptions"
                :key="key"
                :label="value.title"
                @click="selectedTab = value.id"
              />
            </v-tabs>
          </v-col>
          <v-col
            v-if="selectedTab === 'about'"
            class="darkGrey--text"
            cols="12"
          >
            <v-row id="about" class="session-feature">
              <v-col cols="6">
                <div class="align-center text-left mt-6">
                  <div class="mb-4">
                    <span class="titleFont"> Sessions (SDK) </span>
                  </div>
                  <div class="my-3">
                    Get actionable insights into your app's performance and the
                    latencies your users experience.
                  </div>
                  <div class="pt-3">
                    ✅ Rest assured, it won't run in store apps, only in
                    development.
                  </div>
                  <div class="pt-3">
                    ✅ Quick and easy setup process, completed in minutes.
                  </div>
                  <div class="pt-3">
                    ✅ Enables comprehensive feedback directly within the app.
                  </div>
                  <div class="pt-3">
                    ✅ Offers robust tracking of user activities and app
                    performance.
                  </div>
                  <div>
                    <session-code-example
                      :code="swiftConfigureCode"
                    ></session-code-example>
                  </div>
                </div>
              </v-col>
              <v-col class="mt-6" cols="6">
                <div class="mb-4">
                  <span class="titleFont"> FAQ </span>
                </div>
                <v-expansion-panels flat outlined>
                  <v-expansion-panel
                    v-for="(item, index) in faqList"
                    :key="index"
                  >
                    <v-expansion-panel-header class="py-2 px-4">
                      <div class="subTitle darkGrey--text">
                        {{ item.title }}
                      </div>
                      <template #actions>
                        <v-icon left large class="ml-4">expand_more</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div
                        class="darkGrey--text bodyFont"
                        v-html="item.description"
                      ></div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row class="session-feature">
              <v-col cols="6">
                <div class="d-flex flex-column align-center text-left mt-6">
                  <div>
                    <div class="mb-4">
                      <span class="titleFont"> Track Everything </span>
                    </div>
                    <div>
                      <p class="block-paragraph">
                        Catch issues fast and reproduce faster. Sessions journey
                        start with app launch and captures vital information to
                        enable you build a better app.
                      </p>
                      <ul class="mt-4 block-list">
                        <li class="block-list__item">
                          Catch issues fast and reproduce faster.
                        </li>
                        <li class="block-list__item">
                          Sessions journey start with app launch and captures
                          vital information.
                        </li>
                        <li class="block-list__item">
                          Catch issues fast and reproduce faster.
                        </li>
                        <li class="block-list__item">
                          Sessions journey start with app launch and captures
                          vital information.
                        </li>
                      </ul>
                    </div>
                    <div>
                      <session-code-example
                        :code="swiftCustomEventCode"
                      ></session-code-example>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <flex-row class="block-figure"
                  ><img
                    height="500"
                    src="/media/instruction/track.png"
                    style="max-width: 626px"
                    width="626"
                /></flex-row>
              </v-col>
            </v-row>
            <v-row class="session-feature">
              <v-col cols="6">
                <div class="d-flex flex-column align-center text-left mt-6">
                  <div>
                    <div class="mb-4">
                      <span class="titleFont">
                        Rolling & Classic Recordings
                      </span>
                    </div>
                    <div>
                      <p class="block-paragraph">
                        Rolling captures the latest 30 seconds of your screen
                        without sound, always keeping things fresh.
                      </p>
                      <p class="block-paragraph">
                        Classic gives you a longer 5-minute recording with
                        audio, perfect for in-depth insights.
                      </p>
                      <p class="block-paragraph">
                        Both options let you add annotations on-the-go, making
                        your feedback crystal clear.
                      </p>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <flex-row class="block-figure">
                  <img
                    height="659"
                    src="/media/sessions/capture.svg"
                    style="max-width: 641px"
                    width="641"
                  />
                </flex-row>
              </v-col>
            </v-row>
            <v-row class="session-feature">
              <v-col cols="6">
                <div class="d-flex flex-column align-center text-left mt-6">
                  <div>
                    <div class="mb-4">
                      <span class="titleFont"> Identify Testers </span>
                    </div>
                    <div>
                      <p class="block-paragraph">
                        Think of it as a name tag for your users. With
                        TestApp.io, you can link a tester's actions to their
                        unique ID, and even add personal details like their name
                        or email.
                      </p>

                      <p class="block-paragraph">
                        It's like getting to know your testers better, all in
                        one place.
                      </p>
                    </div>
                    <div>
                      <session-code-example
                        :code="swiftIdentify"
                      ></session-code-example>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <flex-row class="block-figure"
                  ><img
                    height="358"
                    src="/media/sessions/identify.svg"
                    style="max-width: 761px"
                    width="761"
                /></flex-row>
              </v-col>
            </v-row>
            <v-row class="session-feature">
              <v-col cols="6">
                <div class="d-flex flex-column align-center text-left mt-6">
                  <div>
                    <div class="mb-4">
                      <span class="titleFont"> In-app Feedback </span>
                    </div>
                    <div>
                      <p class="block-paragraph">
                        Testers can effortlessly pinpoint issues in your app,
                        capturing the moment with screenshots or screen
                        recordings.
                      </p>
                      <p class="block-paragraph">
                        No more guesswork —developers get the exact visuals,
                        ensuring a polished fix for the next release.
                      </p>
                    </div>
                    <div>
                      <session-code-example
                        :code="swiftUIcode"
                      ></session-code-example>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <flex-row class="block-figure">
                  <img
                    height="718"
                    src="/media/sessions/feedback.svg"
                    style="max-width: 761px"
                    width="761"
                  />
                </flex-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="selectedTab === 'platform'" cols="12">
            <v-row>
              <v-col
                cols="12"
                md="4"
                sm="6"
                v-for="(item, index) in sdkLanguageList"
                :key="index"
              >
                <v-card
                  class="cardAlignment"
                  height="100%"
                  @click="openDocs(item)"
                  outlined
                >
                  <v-card-title>
                    <v-icon
                      v-if="item.icon"
                      size="40"
                      left
                      v-text="item.icon"
                    ></v-icon>
                    <img
                      v-if="item.img"
                      :src="item.img"
                      contain
                      height="40"
                      width="40"
                    />
                    <div class="ml-3">
                      <div>
                        <div class="small-font">{{ item.title }}</div>
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                    <div v-if="item.isAvailable">
                      <i
                        v-if="item.platform === 'Android'"
                        style="color: #0a622f; display: flex"
                      >
                        <android-logo height="24" width="24" />
                      </i>
                      <v-icon
                        v-else
                        color="primary"
                        v-text="'mdi-apple'"
                      ></v-icon>
                    </div>

                    <div
                      v-if="!item.isAvailable"
                      class="px-2 cursor-pointer vertical-button mt-1"
                      outlined
                    >
                      <div class="text-center">
                        <div>
                          <i
                            v-if="item.platform === 'Android'"
                            style="color: #0a622f; display: flex"
                          >
                            <android-logo height="24" width="24" />
                          </i>
                          <v-icon
                            v-else
                            color="primary"
                            v-text="'mdi-apple'"
                          ></v-icon>
                        </div>
                        <div class="small-font">Vote</div>
                      </div>
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col class="pb-0 darkGrey--text" cols="12">
                <div class="mb-8">
                  <v-icon color="grey" left small>mdi-help-circle</v-icon>
                  <span>We missed an integration that you need?</span>
                  <span
                    class="ml-1 primary--text cursor-pointer"
                    :class="
                      $vuetify.theme.dark ? 'text-decoration-underline' : ''
                    "
                    @click="openIntercomChat(contactMessage)"
                    >Let us know!</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Modal
      width="900"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      v-model="showAppToken"
    >
      <template #card>
        <app-tokens
          @select="successSelectionToken"
          @close="showAppToken = false"
          fromAppPage
          :app_id="appInfo.id"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import { mapGetters } from "vuex";
import Tab from "@/view/components/Common/Tab";
import apiRoutes from "@/consts/routes/apiRoutes";
import {
  ENABLE_LOCK_PACKAGE,
  ENABLE_LOCK_UNIQUE_RELEASES,
  ENABLE_SESSIONS,
} from "@/store/apps/app.module";
import SessionCodeExample from "@/view/components/Sessions/CodeSnippet.vue";

export default {
  components: {
    Tab,
    AppTokens: () => import("@/view/components/App/AppToken.vue"),
    SessionCodeExample,
  },
  mixins: [veeValidate],
  props: {
    fromSdkPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      busy: false,
      activeTab: 0, // by default, first tab is active
      loadingPackage: false,
      loadingReleaseIncrement: false,
      swiftConfigureCode: `import TestAppIOSDK

TestAppio.setup(
    configuration: .init(
        appToken: "APP_TOKEN",
        environment: .dev  // [.dev, .staging, .production]
    )
)`,
      swiftUIcode: `// To display the feedback bar
TestAppio.bar.show()

// To hide the feedback bar
TestAppio.bar.hide()`,
      swiftCustomEventCode: `// Logging a custom event
TestAppio.log.event(.init(
    name: "Item Purchased",
    parameters: ["item": "Laptop", "value": "$1200"] //optional
))

// Logging an error
TestAppio.log.error(.init(
    name: "Checkout Error",
    parameters: ["error": "Payment gateway timeout"] //optional
))

// Logging a screen transition
TestAppio.log.screen(.init(
    name: "User Profile",
    parameters: ["access": "logged-in"] //optional
))`,
      swiftIdentify: `TestAppio.user.identify(
    userId: "U123456", //required
    name: "Jane Doe", //optional
    email: "jane.doe@example.com", //optional
    imageUrl: "https://url/to/sample-image.png", //optional
    traits: [  //optional
        "age": "30",
        "plan": "premium",
        "ab_test_variant": "variation_A"
    ]
)`,
      contactMessage: {
        message:
          "Hello, I would like to suggest a missing integration for SDK:",
        subject: "Suggesting an integration for SDK",
      },
      sdkLanguageList: [
        {
          title: "Swift",
          value: "swift",
          img: "/media/logos/swift.svg",
          platform: "iOS",
          isAvailable: true,
          linkName: "https://github.com/testappio/ios-sdk",
        },
        {
          title: "Flutter",
          value: "flutter",
          platform: "iOS",
          img: "/media/logos/flutter.svg",
          upVotes: 34,
          isAvailable: true,
          linkName: "https://github.com/testappio/flutter-sdk",
        },
        {
          title: "Kotlin",
          value: "kotlin",
          platform: "Android",
          isAvailable: false,
          img: "/media/logos/kotlin.svg",
          upVotes: 34,
          linkName: "",
        },
        {
          title: "Flutter",
          value: "flutter",
          platform: "Android",
          img: "/media/logos/flutter.svg",
          upVotes: 34,
          isAvailable: false,
          linkName: "",
        },
        {
          title: "React Native",
          value: "react-native",
          platform: "iOS",
          img: "/media/logos/react.svg",
          upVotes: 34,
          isAvailable: false,
        },
        {
          title: "React Native",
          value: "react-native",
          platform: "Android",
          img: "/media/logos/react.svg",
          upVotes: 34,
          isAvailable: false,
        },
        {
          title: "Unreal Engine",
          value: "unreal-engine",
          platform: "Android",
          img: "/media/logos/unreal-engine.svg",
          upVotes: 34,
          isAvailable: false,
        },
        {
          title: "Unreal Engine",
          value: "unreal-engine",
          platform: "iOS",
          img: "/media/logos/unreal-engine.svg",
          upVotes: 34,
          isAvailable: false,
        },
        {
          title: "Unity",
          value: "unity",
          platform: "Android",
          img: "/media/logos/unity.svg",
          upVotes: 34,
          isAvailable: false,
        },
        {
          title: "Unity",
          value: "unity",
          platform: "iOS",
          img: "/media/logos/unity.svg",
          upVotes: 34,
          isAvailable: false,
        },
        {
          title: "NativeScript",
          value: "nativescript",
          platform: "Android",
          img: "/media/logos/nativescript.svg",
          upVotes: 34,
          isAvailable: false,
        },
        {
          title: "NativeScript",
          value: "nativescript",
          platform: "iOS",
          img: "/media/logos/nativescript.svg",
          upVotes: 34,
          isAvailable: false,
        },
        {
          title: "Godot Engine",
          value: "godot",
          platform: "Android",
          img: "/media/logos/godot-game-engine-icon.svg",
          upVotes: 34,
          isAvailable: false,
        },
        {
          title: "Godot Engine",
          value: "godot",
          platform: "iOS",
          img: "/media/logos/godot-game-engine-icon.svg",
          upVotes: 34,
          isAvailable: false,
        },
        {
          title: "Framework 7",
          value: "framework-7",
          platform: "Android",
          img: "/media/logos/framework7.svg",
          upVotes: 34,
          isAvailable: false,
        },
        {
          title: "Framework 7",
          value: "framework-7",
          platform: "iOS",
          img: "/media/logos/framework7.svg",
          upVotes: 34,
          isAvailable: false,
        },
      ],
      tempLockPackage: false,
      tempLockUniqueReleases: false,

      selectedPlatform: "",
      faqList: [
        {
          title: "Is the SDK intended for production apps?",
          description: `No, the SDK is exclusively for development environments and will not function in store environments.`,
        },
        {
          title: "How do I ensure accurate event tracking?",
          description:
            "Start by initializing the TestApp.io SDK using <code>TestAppio.setup(...)</code> as the first step in your app's launch process for precise event tracking.",
        },
        {
          title: "How does the SDK handle user identities? ",
          description:
            "Using <code>TestAppio.user.identify(...)</code> is recommended. Although optional, it significantly aids in quick tester recognition and improves feedback context and data quality.",
        },
        {
          title: "Does the SDK produce any console outputs? ",
          description:
            "Yes, the SDK prints specific information to the console for debugging purposes. This helps developers understand the SDK's internal workings and quickly diagnose potential issues.",
        },
        {
          title: "Are there any dependencies I should be aware of?  ",
          description:
            "No, the TestApp.io SDK does not have any external dependencies.",
        },
        {
          title: "Where can I find a sample app or demo?",
          description:
            "You can explore a practical implementation of the SDK in our <a href='https://testapp.io/sessions' target='_blank'>sample application.</a>",
        },
        {
          title: "Are there any known issues with the SDK?",
          description:
            "Currently, there are no known issues. However, we recommend regularly checking this section or our GitHub repository for updates.",
        },
        {
          title: "What is the status of the SDK?",
          description:
            "The SDK is currently in beta and available for all teams. Please note that this availability is subject to change. We are continually refining and improving, so we appreciate your feedback during this phase.",
        },
      ],
      getAndroidLanguage: [
        {
          category: "Language",
          text: "Java",
          value: "Java",
        },
        {
          category: "Language",
          text: "Kotlin",
          value: "Kotlin",
        },
        {
          category: "Language",
          text: "Dart",
          value: "Dart",
        },
        {
          category: "Games",
          text: "Unity",
          value: "Unity",
        },
        {
          category: "Games",
          text: "Unreal Engine",
          value: "Unreal Engine",
        },
        {
          category: "Games",
          text: "Godot Engine",
          value: "Godot Engine",
        },
        {
          category: "Games",
          text: "Corona SDK",
          value: "Corona SDK",
        },
        {
          category: "Games",
          text: "Construct 3",
          value: "Construct 3",
        },
        {
          category: "Framework",
          text: "Flutter",
          value: "Flutter",
        },
        {
          category: "Framework",
          text: "React Native",
          value: "React Native",
        },
        {
          category: "Framework",
          text: "Nativescript",
          value: "Nativescript",
        },
        {
          category: "Framework",
          text: "Xamarin",
          value: "Xamarin",
        },
        {
          category: "Framework",
          text: "Jetpack",
          value: "Jetpack",
        },
        {
          category: "Framework",
          text: "PhoneGap ",
          value: "PhoneGap ",
        },
        {
          category: "Framework",
          text: "Framework7 ",
          value: "Framework7 ",
        },
        {
          category: "Framework",
          text: "JQuery Mobile ",
          value: "JQuery Mobile ",
        },
        {
          category: "Framework",
          text: "Sencha Touch ",
          value: "Sencha Touch ",
        },
        {
          category: "Framework",
          text: "Appcelerator Titanium",
          value: "Appcelerator Titanium",
        },
      ],
      getiOSLanguage: [
        {
          category: "Language",
          text: "Swift",
          value: "Swift",
        },
        {
          category: "Games",
          text: "Unity",
          value: "Unity",
        },
        {
          category: "Games",
          text: "Unreal Engine",
          value: "Unreal Engine",
        },
        {
          category: "Games",
          text: "Godot Engine",
          value: "Godot Engine",
        },
        {
          category: "Games",
          text: "Corona SDK",
          value: "Corona SDK",
        },
        {
          category: "Games",
          text: "Construct 3",
          value: "Construct 3",
        },
        {
          category: "Framework",
          text: "Flutter",
          value: "Flutter",
        },
        {
          category: "Framework",
          text: "React Native",
          value: "React Native",
        },
        {
          category: "Framework",
          text: "Nativescript",
          value: "Nativescript",
        },
        {
          category: "Framework",
          text: "Xamarin",
          value: "Xamarin",
        },
        {
          category: "Framework",
          text: "Jetpack",
          value: "Jetpack",
        },
        {
          category: "Framework",
          text: "PhoneGap ",
          value: "PhoneGap ",
        },
        {
          category: "Framework",
          text: "Framework7 ",
          value: "Framework7 ",
        },
        {
          category: "Framework",
          text: "JQuery Mobile ",
          value: "JQuery Mobile ",
        },
        {
          category: "Framework",
          text: "Sencha Touch ",
          value: "Sencha Touch ",
        },
        {
          category: "Framework",
          text: "Appcelerator Titanium",
          value: "Appcelerator Titanium",
        },
      ],
      androidLanguage: "",
      iOSSearch: "",
      androidSearch: "",
      selectedToken: "",
      showAppToken: false,
      iosLanguage: "",
      selectedTab: "about",
      newAndroidLanguage: "",
      newIosLanguage: "",
      tabOptions: [
        {
          title: "About",
          value: 0,
          id: "about",
        },
        {
          title: "Platforms",
          value: 1,
          id: "platform",
        },
      ],
      tab: 0,
      disableIntersection: false,
      idTimeout: null,
    };
  },
  computed: {
    ...mapGetters({
      appInfo: "getAppInfo",
    }),
    isEnableButtonActive() {
      return (
        this.appInfo?.settings?.lock_package &&
        this.appInfo?.settings?.lock_unique_releases
      );
    },
    showNotify() {
      if (
        this.selectedPlatform === "iOS" &&
        (this.iosLanguage === "Swift" || this.iosLanguage === "Flutter")
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    appInfo: {
      immediate: true, // this ensures the handler is called right away
      handler(newVal) {
        this.activeTab = newVal?.settings?.sessions_enabled ? 1 : 0;
      },
    },
    selectedPlatform() {
      this.iosLanguage = "";
      this.androidLanguage = "";
    },
    tab() {},
  },

  mounted() {
    const features = Array.from(document.querySelectorAll(".session-feature"));
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.disableIntersection) {
            this.tab = features.indexOf(entry.target);
          }
        });
      },
      { threshold: 1 }
    );

    features.forEach((f) => {
      observer.observe(f);
    });
  },
  methods: {
    async enableLockPackage(newValue) {
      if (
        this.loadingPackage ||
        newValue === this.appInfo.settings.lock_package
      )
        return;

      this.loadingPackage = true;
      this.tempLockPackage = newValue;

      try {
        const response = await this.$store.dispatch(ENABLE_LOCK_PACKAGE, {
          app_id: this.appInfo.id,
          enabled: newValue,
        });
        this.appInfo.settings.lock_package = newValue;
        this.appInfo.package = response.package;
        this.notifyUserMessage({ message: response.message });
      } catch (err) {
        this.tempLockPackage = !newValue; // Revert the temp state on error
        this.notifyErrorMessage(err.message);
      } finally {
        this.loadingPackage = false;
      }
    },

    async enableLockUniqueReleases(newValue) {
      if (
        this.loadingReleaseIncrement ||
        newValue === this.appInfo.settings.lock_unique_releases
      )
        return;

      this.loadingReleaseIncrement = true;
      this.tempLockUniqueReleases = newValue;

      try {
        const response = await this.$store.dispatch(
          ENABLE_LOCK_UNIQUE_RELEASES,
          {
            app_id: this.appInfo.id,
            enabled: newValue,
          }
        );
        this.appInfo.settings.lock_unique_releases = newValue;
        this.notifyUserMessage({ message: response.message });
      } catch (err) {
        this.tempLockUniqueReleases = !newValue; // Revert the temp state on error
        this.notifyErrorMessage(err.message);
      } finally {
        this.loadingReleaseIncrement = false;
      }
    },
    selectToken() {
      if (this.$route.params.app_id) {
        this.showAppToken = true;
      } else {
        this.$router.push({
          name: "apps",
          query: { to: "app-integrations", tab: "sessions" },
        });
      }
    },
    successSelectionToken(token) {
      this.selectedToken = token;
      this.showAppToken = false;
    },

    //Enable Sessions
    async enableSessions() {
      if (this.busy) return;
      this.busy = true;
      try {
        const response = await this.$store.dispatch(ENABLE_SESSIONS, {
          app_id: this.$route.params.app_id,
          enabled: true,
        });
        this.appInfo.settings.sessions_enabled = true;
        this.activeTab = 1;
        this.notifyUserMessage({ message: response.message });
      } catch (err) {
        this.notifyErrorMessage(err.message);
      } finally {
        this.busy = false;
      }
    },
    async addIosLanguage() {
      if (await this.validateAllFields()) {
        if (!this.newIosLanguage && !this.iOSSearch) {
          return;
        }
        this.getiOSLanguage.push({
          text: this.iOSSearch || this.newIosLanguage,
          value: this.iOSSearch || this.newIosLanguage,
        });
        this.iosLanguage = this.iOSSearch || this.newIosLanguage;
        this.newIosLanguage = "";
      }
    },
    async addAndroidLanguage() {
      if (await this.validateAllFields()) {
        if (!this.androidSearch && !this.newAndroidLanguage) {
          return;
        }
        this.getAndroidLanguage.push({
          text: this.androidSearch || this.newAndroidLanguage,
          value: this.androidSearch || this.newAndroidLanguage,
        });
        this.androidLanguage = this.androidSearch || this.newAndroidLanguage;
        this.newAndroidLanguage = "";
      }
    },
    openSchedule() {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/omar-massad/book-a-demo",
      });
    },
    openDocs(detail) {
      this.activeTab = 2;
      let link = {
        Swift: "https://github.com/testappio/ios-sdk",
        Flutter: "https://github.com/testappio/flutter-sdk",
      };
      if (
        ["swift", "flutter"].includes(detail.value) &&
        detail.platform === "iOS"
      ) {
        window.open(link[detail.title], "_blank");
      } else {
        this.notifyWhenAvailable(detail);
      }
    },
    notifyWhenAvailable(detail) {
      let dataToPost = {
        app_id: this.$route.params.app_id,
        feature: "Session",
        meta: {
          android: detail?.value,
          ios: detail?.value,
        },
        message: "",
      };
      if (detail?.platform === "Android") {
        delete dataToPost.meta.ios;
      } else {
        delete dataToPost.meta.android;
      }
      return this.$coreApi
        .post(apiRoutes.core.session.notifyWhenAvailable, dataToPost)
        .then(({ data }) => {
          this.notifyUserMessage({ message: data.message });
        })
        .catch((error) => {
          this.notifyErrorMessage(error?.message);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    scrollToSection(id) {
      this.disableIntersection = true;
      clearTimeout(this.idTimeout);
      this.idTimeout = setTimeout(() => {
        this.disableIntersection = false;
      }, 500);
      const section = document.getElementById(id);
      if (section) {
        const positionToScroll = section.offsetTop - 100; // 100px offset from the top

        window.scrollTo({
          top: positionToScroll,
          behavior: "smooth",
        });
      }
    },
    navigateToSessions() {
      this.$router.push({
        name: "app-integrations",
        params: { tab: "sessions" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-background {
  background: none !important;
}
.cardBackgroud {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: linear-gradient(
      262deg,
      rgba(255, 184, 34, 0.12) 0%,
      rgba(88, 185, 240, 0.12) 100%
    ),
    #fff;
}

.session-feature {
  &:nth-child(even) {
    flex-direction: row-reverse !important;
  }

  & + & {
    margin-top: 200px;
  }
}

.block-list {
  &__item {
    font-size: 16px;
    max-width: 70ch;
  }
}

.block-paragraph {
  font-size: 16px;
  max-width: 60ch;
}

.block-figure {
  & img {
    height: auto;
    margin: auto;
    object-fit: cover;
    width: 100%;
  }
}
.cardAlignment {
  display: grid;
  align-items: center;
}
.small-font {
  font-size: 1rem;
}
.vertical-button {
  justify-content: center;
  align-items: center;
  border: 1px solid darkgrey;
  border-radius: 10px;
}
</style>

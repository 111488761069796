var render = function render(){
  var _vm$appInfo, _vm$appInfo$settings, _vm$appInfo2, _vm$appInfo2$settings, _vm$appInfo3, _vm$appInfo3$settings, _vm$appInfo4, _vm$appInfo4$settings;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": _vm.fromSdkPage ? '11' : '9'
    }
  }, [_c('v-row', [_c('v-col', {
    class: _vm.fromSdkPage ? '' : 'mt-15'
  }, [_c('v-card', {
    staticClass: "cardBackgroud pa-12",
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "",
      "flat": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticStyle: {
      "margin-left": "68px",
      "padding": "40px 0"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('v-icon', {
    staticClass: "mt-n2",
    attrs: {
      "color": "primary",
      "left": "",
      "size": "40"
    }
  }, [_vm._v("mdi-timeline-text-outline ")]), _c('span', [_c('span', {
    staticClass: "headingTitle mr-2 semi-bold"
  }, [_vm._v("Sessions")]), _c('sup', {
    staticClass: "smallFont"
  }, [_vm._v("BETA")])])], 1)])], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "mb-3",
    staticStyle: {
      "max-width": "43ch"
    }
  }, [_c('b', [_vm._v("Track everything")]), _vm._v(" and collect "), _c('b', [_vm._v("feedback")]), _vm._v(" from your testers from inside your app to make it ready for real users in app stores. ")]), _c('div', {
    staticClass: "mb-3"
  }, [_vm._v(" 2-mins to integrate inside your app! ")])])], 1), (_vm$appInfo = _vm.appInfo) !== null && _vm$appInfo !== void 0 && (_vm$appInfo$settings = _vm$appInfo.settings) !== null && _vm$appInfo$settings !== void 0 && _vm$appInfo$settings.sessions_enabled && _vm.$route.params.app_id ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none mr-2",
    attrs: {
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.selectToken
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-select-all")]), _vm._v(" Select App Token ")], 1), _c('v-btn', {
    staticClass: "text-transform-none mr-2",
    attrs: {
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "link": "",
      "text": "",
      "target": "_blank",
      "href": "https://testapp.io/sessions"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-page-next-outline")]), _vm._v(" Learn more ")], 1)], 1), _vm.selectedToken ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.selectedToken ? _c('v-chip', {
    staticClass: "black--text cursor-pointer",
    attrs: {
      "color": "grey",
      "label": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('select-token');
      }
    }
  }, [_c('MaxText', {
    attrs: {
      "text": _vm.selectedToken,
      "max": "40"
    }
  })], 1) : _vm._e(), _vm.selectedToken ? _c('v-btn', {
    attrs: {
      "disabled": !_vm.selectedToken,
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.copy(_vm.selectedToken);
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-content-copy')
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), !((_vm$appInfo2 = _vm.appInfo) !== null && _vm$appInfo2 !== void 0 && (_vm$appInfo2$settings = _vm$appInfo2.settings) !== null && _vm$appInfo2$settings !== void 0 && _vm$appInfo2$settings.lock_package) && _vm.$route.params.app_id ? _c('v-row', {
    staticClass: "d-flex align-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "input-value": _vm.tempLockPackage,
      "label": "Lock Package",
      "disabled": _vm.loadingPackage
    },
    on: {
      "change": _vm.enableLockPackage
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-icon', _vm._g({
          staticClass: "ml-2",
          attrs: {
            "small": ""
          }
        }, on), [_vm._v(" mdi-help-circle-outline ")])];
      }
    }], null, false, 2019398599)
  }, [_c('span', [_vm._v(" Locking the package ensures that releases and sessions are tied together, providing better tracking and analysis. ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.loadingPackage ? _c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "size": "24"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), !_vm.$route.params.app_id ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'apps',
          query: {
            to: 'app-integrations',
            tab: 'sessions'
          }
        });
      }
    }
  }, [_vm._v("Get started")])], 1)], 1) : _vm._e(), !((_vm$appInfo3 = _vm.appInfo) !== null && _vm$appInfo3 !== void 0 && (_vm$appInfo3$settings = _vm$appInfo3.settings) !== null && _vm$appInfo3$settings !== void 0 && _vm$appInfo3$settings.lock_unique_releases) && _vm.$route.params.app_id ? _c('v-row', {
    staticClass: "d-flex align-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "input-value": _vm.tempLockUniqueReleases,
      "label": "Unique Releases",
      "disabled": _vm.loadingReleaseIncrement
    },
    on: {
      "change": _vm.enableLockUniqueReleases
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-icon', _vm._g({
          staticClass: "ml-2",
          attrs: {
            "small": ""
          }
        }, on), [_vm._v(" mdi-help-circle-outline ")])];
      }
    }], null, false, 2019398599)
  }, [_c('span', [_vm._v(" Locking unique releases allows for precise error tracking by mapping errors directly to specific release versions. ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.loadingReleaseIncrement ? _c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "size": "24"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), !((_vm$appInfo4 = _vm.appInfo) !== null && _vm$appInfo4 !== void 0 && (_vm$appInfo4$settings = _vm$appInfo4.settings) !== null && _vm$appInfo4$settings !== void 0 && _vm$appInfo4$settings.sessions_enabled) && _vm.$route.params.app_id ? _c('v-row', {
    staticClass: "pl-3 mt-4"
  }, [_c('v-btn', {
    attrs: {
      "disabled": !_vm.isEnableButtonActive || _vm.busy,
      "color": "primary"
    },
    on: {
      "click": _vm.enableSessions
    }
  }, [_vm._v("Enable")])], 1) : _vm._e()], 1)]), _c('v-col', {
    staticClass: "text-right",
    staticStyle: {
      "display": "flex"
    },
    attrs: {
      "cols": "6"
    }
  }, [_c('img', {
    staticStyle: {
      "aspect-ratio": "606 / 446"
    },
    attrs: {
      "src": "/media/sessions/sessions.svg",
      "width": "100%"
    }
  })])], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticStyle: {
      "position": "sticky",
      "top": "52px"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.tabOptions, function (value, key) {
    return _c('tab', {
      key: key,
      attrs: {
        "label": value.title
      },
      on: {
        "click": function click($event) {
          _vm.selectedTab = value.id;
        }
      }
    });
  }), 1)], 1), _vm.selectedTab === 'about' ? _c('v-col', {
    staticClass: "darkGrey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "session-feature",
    attrs: {
      "id": "about"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "align-center text-left mt-6"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('span', {
    staticClass: "titleFont"
  }, [_vm._v(" Sessions (SDK) ")])]), _c('div', {
    staticClass: "my-3"
  }, [_vm._v(" Get actionable insights into your app's performance and the latencies your users experience. ")]), _c('div', {
    staticClass: "pt-3"
  }, [_vm._v(" ✅ Rest assured, it won't run in store apps, only in development. ")]), _c('div', {
    staticClass: "pt-3"
  }, [_vm._v(" ✅ Quick and easy setup process, completed in minutes. ")]), _c('div', {
    staticClass: "pt-3"
  }, [_vm._v(" ✅ Enables comprehensive feedback directly within the app. ")]), _c('div', {
    staticClass: "pt-3"
  }, [_vm._v(" ✅ Offers robust tracking of user activities and app performance. ")]), _c('div', [_c('session-code-example', {
    attrs: {
      "code": _vm.swiftConfigureCode
    }
  })], 1)])]), _c('v-col', {
    staticClass: "mt-6",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('span', {
    staticClass: "titleFont"
  }, [_vm._v(" FAQ ")])]), _c('v-expansion-panels', {
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, _vm._l(_vm.faqList, function (item, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', {
      staticClass: "py-2 px-4",
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function fn() {
          return [_c('v-icon', {
            staticClass: "ml-4",
            attrs: {
              "left": "",
              "large": ""
            }
          }, [_vm._v("expand_more")])];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "subTitle darkGrey--text"
    }, [_vm._v(" " + _vm._s(item.title) + " ")])]), _c('v-expansion-panel-content', [_c('div', {
      staticClass: "darkGrey--text bodyFont",
      domProps: {
        "innerHTML": _vm._s(item.description)
      }
    })])], 1);
  }), 1)], 1)], 1), _c('v-row', {
    staticClass: "session-feature"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-center text-left mt-6"
  }, [_c('div', [_c('div', {
    staticClass: "mb-4"
  }, [_c('span', {
    staticClass: "titleFont"
  }, [_vm._v(" Track Everything ")])]), _c('div', [_c('p', {
    staticClass: "block-paragraph"
  }, [_vm._v(" Catch issues fast and reproduce faster. Sessions journey start with app launch and captures vital information to enable you build a better app. ")]), _c('ul', {
    staticClass: "mt-4 block-list"
  }, [_c('li', {
    staticClass: "block-list__item"
  }, [_vm._v(" Catch issues fast and reproduce faster. ")]), _c('li', {
    staticClass: "block-list__item"
  }, [_vm._v(" Sessions journey start with app launch and captures vital information. ")]), _c('li', {
    staticClass: "block-list__item"
  }, [_vm._v(" Catch issues fast and reproduce faster. ")]), _c('li', {
    staticClass: "block-list__item"
  }, [_vm._v(" Sessions journey start with app launch and captures vital information. ")])])]), _c('div', [_c('session-code-example', {
    attrs: {
      "code": _vm.swiftCustomEventCode
    }
  })], 1)])])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('flex-row', {
    staticClass: "block-figure"
  }, [_c('img', {
    staticStyle: {
      "max-width": "626px"
    },
    attrs: {
      "height": "500",
      "src": "/media/instruction/track.png",
      "width": "626"
    }
  })])], 1)], 1), _c('v-row', {
    staticClass: "session-feature"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-center text-left mt-6"
  }, [_c('div', [_c('div', {
    staticClass: "mb-4"
  }, [_c('span', {
    staticClass: "titleFont"
  }, [_vm._v(" Rolling & Classic Recordings ")])]), _c('div', [_c('p', {
    staticClass: "block-paragraph"
  }, [_vm._v(" Rolling captures the latest 30 seconds of your screen without sound, always keeping things fresh. ")]), _c('p', {
    staticClass: "block-paragraph"
  }, [_vm._v(" Classic gives you a longer 5-minute recording with audio, perfect for in-depth insights. ")]), _c('p', {
    staticClass: "block-paragraph"
  }, [_vm._v(" Both options let you add annotations on-the-go, making your feedback crystal clear. ")])])])])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('flex-row', {
    staticClass: "block-figure"
  }, [_c('img', {
    staticStyle: {
      "max-width": "641px"
    },
    attrs: {
      "height": "659",
      "src": "/media/sessions/capture.svg",
      "width": "641"
    }
  })])], 1)], 1), _c('v-row', {
    staticClass: "session-feature"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-center text-left mt-6"
  }, [_c('div', [_c('div', {
    staticClass: "mb-4"
  }, [_c('span', {
    staticClass: "titleFont"
  }, [_vm._v(" Identify Testers ")])]), _c('div', [_c('p', {
    staticClass: "block-paragraph"
  }, [_vm._v(" Think of it as a name tag for your users. With TestApp.io, you can link a tester's actions to their unique ID, and even add personal details like their name or email. ")]), _c('p', {
    staticClass: "block-paragraph"
  }, [_vm._v(" It's like getting to know your testers better, all in one place. ")])]), _c('div', [_c('session-code-example', {
    attrs: {
      "code": _vm.swiftIdentify
    }
  })], 1)])])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('flex-row', {
    staticClass: "block-figure"
  }, [_c('img', {
    staticStyle: {
      "max-width": "761px"
    },
    attrs: {
      "height": "358",
      "src": "/media/sessions/identify.svg",
      "width": "761"
    }
  })])], 1)], 1), _c('v-row', {
    staticClass: "session-feature"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-center text-left mt-6"
  }, [_c('div', [_c('div', {
    staticClass: "mb-4"
  }, [_c('span', {
    staticClass: "titleFont"
  }, [_vm._v(" In-app Feedback ")])]), _c('div', [_c('p', {
    staticClass: "block-paragraph"
  }, [_vm._v(" Testers can effortlessly pinpoint issues in your app, capturing the moment with screenshots or screen recordings. ")]), _c('p', {
    staticClass: "block-paragraph"
  }, [_vm._v(" No more guesswork —developers get the exact visuals, ensuring a polished fix for the next release. ")])]), _c('div', [_c('session-code-example', {
    attrs: {
      "code": _vm.swiftUIcode
    }
  })], 1)])])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('flex-row', {
    staticClass: "block-figure"
  }, [_c('img', {
    staticStyle: {
      "max-width": "761px"
    },
    attrs: {
      "height": "718",
      "src": "/media/sessions/feedback.svg",
      "width": "761"
    }
  })])], 1)], 1)], 1) : _vm._e(), _vm.selectedTab === 'platform' ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_vm._l(_vm.sdkLanguageList, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "4",
        "sm": "6"
      }
    }, [_c('v-card', {
      staticClass: "cardAlignment",
      attrs: {
        "height": "100%",
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.openDocs(item);
        }
      }
    }, [_c('v-card-title', [item.icon ? _c('v-icon', {
      attrs: {
        "size": "40",
        "left": ""
      },
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    }) : _vm._e(), item.img ? _c('img', {
      attrs: {
        "src": item.img,
        "contain": "",
        "height": "40",
        "width": "40"
      }
    }) : _vm._e(), _c('div', {
      staticClass: "ml-3"
    }, [_c('div', [_c('div', {
      staticClass: "small-font"
    }, [_vm._v(_vm._s(item.title))])])]), _c('v-spacer'), item.isAvailable ? _c('div', [item.platform === 'Android' ? _c('i', {
      staticStyle: {
        "color": "#0a622f",
        "display": "flex"
      }
    }, [_c('android-logo', {
      attrs: {
        "height": "24",
        "width": "24"
      }
    })], 1) : _c('v-icon', {
      attrs: {
        "color": "primary"
      },
      domProps: {
        "textContent": _vm._s('mdi-apple')
      }
    })], 1) : _vm._e(), !item.isAvailable ? _c('div', {
      staticClass: "px-2 cursor-pointer vertical-button mt-1",
      attrs: {
        "outlined": ""
      }
    }, [_c('div', {
      staticClass: "text-center"
    }, [_c('div', [item.platform === 'Android' ? _c('i', {
      staticStyle: {
        "color": "#0a622f",
        "display": "flex"
      }
    }, [_c('android-logo', {
      attrs: {
        "height": "24",
        "width": "24"
      }
    })], 1) : _c('v-icon', {
      attrs: {
        "color": "primary"
      },
      domProps: {
        "textContent": _vm._s('mdi-apple')
      }
    })], 1), _c('div', {
      staticClass: "small-font"
    }, [_vm._v("Vote")])])]) : _vm._e()], 1)], 1)], 1);
  }), _c('v-col', {
    staticClass: "pb-0 darkGrey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mb-8"
  }, [_c('v-icon', {
    attrs: {
      "color": "grey",
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-help-circle")]), _c('span', [_vm._v("We missed an integration that you need?")]), _c('span', {
    staticClass: "ml-1 primary--text cursor-pointer",
    class: _vm.$vuetify.theme.dark ? 'text-decoration-underline' : '',
    on: {
      "click": function click($event) {
        return _vm.openIntercomChat(_vm.contactMessage);
      }
    }
  }, [_vm._v("Let us know!")])], 1)])], 2)], 1) : _vm._e()], 1)], 1)], 1), _c('Modal', {
    attrs: {
      "width": "900",
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('app-tokens', {
          attrs: {
            "fromAppPage": "",
            "app_id": _vm.appInfo.id
          },
          on: {
            "select": _vm.successSelectionToken,
            "close": function close($event) {
              _vm.showAppToken = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showAppToken,
      callback: function callback($$v) {
        _vm.showAppToken = $$v;
      },
      expression: "showAppToken"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }